import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import Seo from "../components/seo";
//Images
import { StaticImage } from "gatsby-plugin-image";

const Builders = () => {
    return (
        <Layout>
             <Seo
      title="Constructeurs immobiliers - BIKESAFE"
      description="Les produits compacts de Bike Safe permettent aux contructeurs immobiliers de prévoir un système de rangement pour vélos en toute simplicité. N'hésitez pas à contacter l'un de nos conseillers qui pourra vous recommander la solution la plus adaptée !"
    />
            <Container>
                <Row>
                    <Col sm="12">
                        <h1 className="mt-5 mb-5 text-center">
                            Constructeurs immobiliers
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <p className="flow-text">
                            Les produits compacts de Bike Safe permettent aux contructeurs immobiliers de prévoir un système de rangement pour vélos en toute simplicité. N'hésitez pas à contacter l'un de nos conseillers qui pourra vous recommander la solution la plus adaptée !
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <StaticImage placeholder="blurred"  src="../images/bikestand.jpg" className="shadow mt-5 mb-5 fluid rounded" />
                        <p>
                            <em className="flow-text">« Nous avons été pleinement satisfaits de votre service. Ce qui est plutôt agréable en cette période difficile ! »
                                <strong> James Whiteley (acheteur), Sir Robert Mcalpine.</strong>.</em></p>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default Builders;